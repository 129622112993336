<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        
        <v-toolbar flat dense class="tc-title">
                <router-link to="student-skill-certification" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
                <v-toolbar-title>Experience Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="logi mx-2" fab small @click="dialog_add=true"><v-icon>mdi-plus</v-icon></v-btn>
                <router-link to="student-extra-curricular" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
        </v-toolbar>
        <!--<template>
            <v-toolbar flat dense color="white">
                  <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <span style="width: 20px"></span>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> Add New Record</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
           <v-data-table :headers="headers" :items="exprrience_list" class="elevation-1" :search="search">
                <template v-slot:item.status="{ item }">
                    <span v-if="item.isworking==true">
                        Yes
                    </span>
                    <span v-else>
                        No
                    </span>
                </template>
                <template v-slot:item.docUrl="{ item }">
                    <span v-if="item.docUrl=='NA'">
                    {{ item.docUrl }}
                    </span>
                    <span v-else>
                       <a v-bind:href="item.docUrl" target="blank" class="alink"><v-icon color="success">mdi-cloud-download</v-icon></a>
                    </span>
                </template>
                
                <template v-slot:item.edit="{ item }">
                    <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                    <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                </template>
           </v-data-table>
        </template>-->
        <v-row>
            <v-col cols="12" sm='5' v-for="item in exprrience_list" :key="item">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{item.organization_name}}
                    </v-card-title>
                    <v-card-text>
                        <v-row style="margin: 0px !important; padding: 0px !important" align="left">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important" align="left">
                                <span class="lbl" align="left">Designation</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.designation}}
                            </v-col>
                        </v-row>
                        <!--<v-row>
                            <v-col cols="6" sm="4">
                                <span class="lbl">Role</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.designation}}
                            </v-col>
                        </v-row>-->
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Description</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.work_description}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Start/End Date</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.start_date_show}} - {{item.end_date_show}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Currently Working</span>
                            </v-col>
                            <v-col cols="6" md="6" v-if="item.isworking==true" style="margin: 0px !important; padding: 0px !important">
                                : Yes
                            </v-col>
                            <v-col cols="6" md="6" v-else style="margin: 0px !important; padding: 0px !important">
                                : No
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Experience</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : Years - {{item.years}} Months - {{item.months}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Letter</span>
                            </v-col>
                            <v-col cols="6" md="6" v-if="item.docUrl=='NA'" style="margin: 0px !important; padding: 0px !important">
                                : NA
                            </v-col>
                            <v-col cols="6" md="6" v-else style="margin: 0px !important; padding: 0px !important">
                                : <a v-bind:href="item.docUrl" target="blank" class="alink">{{item.filename}}</a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                                <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                                <v-spacer></v-spacer>
                                <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="700px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add Experience Details

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Orgnaization Name" label="Orgnaization Name" v-model="savedata.org_name" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.savedata.org_name ? 'Please enter organization name' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Designation" label="Designation" v-model="savedata.designation" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.savedata.designation ? 'Please enter designation' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="industry_list" label="Industry" v-model="savedata.industry" item-text="industry" item-value="id" required :rules="[v => !!v || 'required']"
                                        :error-messages="error.savedata.industry ? 'Please select industry' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="year_list" label="Year" v-model="savedata.year" item-text="year" item-value="id" required 
                                            :error-messages="error.savedata.year ? 'Please select year' : ''" ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="month_list" label="Months" v-model="savedata.months" item-text="month" item-value="id" required 
                                            :error-messages="error.savedata.months ? 'Please select month' : ''" ></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="iscworking_list" label="Is Currently Working" v-model="savedata.isworking" item-text="name" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.savedata.isworking ? 'Please select is currently working..' : ''" ></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-file-input type="file" placeholder="Select Experience Letter File" prepend-icon="mdi-file" label="Experience Letter File" ref="file" show-size v-model="savedata.file" ></v-file-input>
                                        <font style="color: red; font-size:11px;">&emsp;&emsp;File Size Should be less than 250 kb,JPEG, JPG & PDF </font>
                                    </v-col>
                                </v-row>
                                <br />
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-textarea placeholder="Enter Experience Description" label="Experience Description" height="40px" v-model="savedata.description" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.savedata.description ? 'Please enter description' : ''"></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        Start Date :<br />
                                        <v-text-field type="date" placeholder="Start Date" v-model="savedata.start_date" :rules="[v => !!v || 'required']"
                                        :error-messages="error.savedata.start_date ? 'Please Enter Approval Letter Date' : ''
                                    "></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        End Date :<br />
                                        <v-text-field type="date" placeholder="End Date" v-model="savedata.end_date" :rules="[v => !!v || 'required']"
                                        :error-messages="error.savedata.end_date ? 'Please select end date' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_add= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->

                             <v-btn color="primary darken-1"  outlined text @click="dialog_add= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="700px">
                    <v-card>
                        <v-card-title class="card-title">
                           Edit Experience Details

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Orgnaization Name" label="Orgnaization Name" v-model="edit_data.org_name" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.edit_data.org_name ? 'Please enter organization name' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field placeholder="Enter Designation" label="Designation" v-model="edit_data.designation" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.edit_data.designation ? 'Please enter Designation' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="industry_list" label="Industry" v-model="edit_data.industry" item-text="industry" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.industry ? 'Please select industry' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="year_list" label="Year" v-model="edit_data.year" item-text="year" item-value="id" :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.year ? 'Please select year' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select :items="month_list" label="Months" v-model="edit_data.months" item-text="month" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.month ? 'Please select month' : ''"></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select :items="iscworking_list" label="Is Currently Working" v-model="edit_data.isworking" item-text="name" item-value="id" required :rules="[v => !!v || 'required']"
                                            :error-messages="error.edit_data.isworking ? 'Please select is currently working...' : ''"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input type="file" placeholder="Select Experience Letter File" prepend-icon="mdi-file" label="Experience Letter File" ref="file" show-size v-model="edit_data.file"></v-file-input>
                                        <span>Filename : &nbsp;</span>{{ file_name }}<br>
                                        <font style="color: red; font-size:11px;">&emsp;&emsp;File Size Should be less than 250 kb,JPEG, JPG & PDF </font>
                                    </v-col>
                                </v-row>
                                <br />
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-textarea placeholder="Enter Experience Description" label="Experience Description" height="40px" v-model="edit_data.description" :rules="[v => !!v || 'required']"
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                      :error-messages="error.edit_data.description ? 'Please enter description' : ''"></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        Start Date :<br />
                                        <v-text-field type="date" placeholder="Start Date" v-model="edit_data.start_date" 
                                        :error-messages="error.edit_data.start_date ? 'Please select start date' : ''"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        End Date :{{edit_data.end_date}}<br />
                                        <v-text-field type="date" placeholder="End Date" v-model="edit_data.end_date" 
                                        :error-messages="error.edit_data.end_date ? 'Please select end date' : ''"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_edit= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="edit()">Update</v-btn> -->
                             <v-btn color="primary darken-1"  outlined text @click="dialog_edit= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Record Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.exp_id" />
                        <v-card-text>
                            Are you sure you want Delete this Record?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Please wait File uploding...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
</template>
<script>
    import axios from "axios";
    import StudentProfile from "./StudentProfile";
    export default {
        components: {
            StudentProfile,
        },
        data: () => ({
            dialog_upload: false,
            headers: [
                {
                    text: "Sr. No.",
                    align: "left",
                    sortable: false,
                    value: "srno",
                },
                {
                    text: "Orgnaization",
                    align: "left",
                    sortable: false,
                    value: "organization_name",
                },
                {
                    text: "Designation",
                    align: "left",
                    sortable: false,
                    value: "designation",
                },
                {
                    text: "Role",
                    align: "left",
                    sortable: false,
                    value: "designation",
                },
                {
                    text: "Working Description",
                    align: "left",
                    sortable: false,
                    value: "work_description",
                },
                {
                    text: "Start Date",
                    align: "left",
                    sortable: false,
                    value: "start_date_show",
                },
                {
                    text: "End Date",
                    align: "left",
                    sortable: false,
                    value: "end_date_show",
                },
                {
                    text: "Is Currently Working",
                    align: "left",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "Years",
                    align: "left",
                    sortable: false,
                    value: "years",
                },
                {
                    text: "Months",
                    align: "left",
                    sortable: false,
                    value: "months",
                },
                {
                    text: "Experience Letter",
                    align: "left",
                    sortable: false,
                    value: "docUrl",
                },
               
                {
                    text: "Edit",
                    value: "edit",
                    sortable: false,
                },
                {
                    text: "Delete",
                    value: "delete",
                    sortable: false,
                },
            ],
            savedata: {
                file: null,
                org_name: null,
                designation: null,
                industry: null,
                year: null,
                months: null,
                isworking: null,
                start_date: null,
                end_date: null,
                description: null,
            },

            edit_data: {
                file: null,
                org_name: null,
                designation: null,
                industry: null,
                year: null,
                months: null,
                isworking: null,
                start_date: null,
                end_date: null,
                description: null,
                id: null,
            },
            exprrience_list: "",
            file_name: "",
            error: {
                
                savedata: {
                    org_name: false,
                    designation: false,
                    industry: false,
                    year: false,
                    months: false,
                    isworking: false,
                    description: false,
                    start_date: false,
                    end_date: false,
                },
                edit_data: {
                    org_name: false,
                    designation: false,
                    industry: false,
                    year: false,
                    months: false,
                    isworking: false,
                    description: false,
                    start_date: false,
                    end_date: false,
                },
            },
            year_list: [
                { id: 0, year: "0" },
                { id: 1, year: "1" },
                { id: 2, year: "2" },
                { id: 3, year: "3" },
                { id: 4, year: "4" },
                { id: 5, year: "5" },
                { id: 6, year: "6" },
                { id: 7, year: "7" },
                { id: 8, year: "8" },
                { id: 9, year: "9" },
                { id: 10, year: "10" },
                { id: 11, year: "11" },
                { id: "12", year: "12" },
            ],
            month_list: [
                { id: 0, month: "0" },
                { id: 1, month: "1" },
                { id: 2, month: "2" },
                { id: 3, month: "3" },
                { id: 4, month: "4" },
                { id: 5, month: "5" },
                { id: 6, month: "6" },
                { id: 7, month: "7" },
                { id: 8, month: "8" },
                { id: 9, month: "9" },
                { id: 10, month: "10" },
                { id: 11, month: "11" },
                { id: "12", month: "12" },
            ],
            iscworking_list: [
                { id: true, name: "Yes" },
                { id: false, name: "No" },
            ],
            dialog_add: false,
            dialog_edit: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            industry_list: [],

            deleteItem: {
                exp_id: "",
            },
            dialog_delete: false,
        }),
        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                axios
                    .post("/LearnerData/learnerExperience")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.exprrience_list = res.data.experience_List;
                            this.industry_list = res.data.Industry_List;
                        } else {
                            this.showSnackbar("#b71c1c", "No Data");
                            //window.console.log(res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        //window.console.log(error);
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                //alert("ID "+item.isworking)
                this.dialog_edit = true;
                this.edit_data.org_name = item.organization_name;
                this.edit_data.designation = item.designation;
                this.edit_data.industry = item.industry_id;
                this.edit_data.months = item.months;
                this.edit_data.year = item.years;
                this.edit_data.start_date = item.startdate;
                this.edit_data.end_date = item.enddate;
                this.edit_data.description = item.work_description;
                this.edit_data.isworking = item.isworking;
                this.edit_data.id = item.id;
                this.file_name = item.experience_letter_file_name;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            hasWhiteSpace(s) {
                var status = false;
                var reWhiteSpace = new RegExp(/^\s+$/);

                // Check for white space
                if (reWhiteSpace.test(s)) {
                    // alert("Please Check Your Fields For Spaces");
                    //   return true;
                    return /^\s/.test(s);
                }
                //   return false;
                return /^\s/.test(s);
            },
            save() {
                if (this.savedata.org_name == null) {
                    this.showSnackbar("#b71c1c", "Enter Orgnaization Name...");
                    this.error.savedata.org_name = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.org_name)){
                        this.showSnackbar("#b71c1c", "Enter valid organization name...");
                        return;
                }else if (this.savedata.designation == null) {
                    this.showSnackbar("#b71c1c", "Enter Designation ..");
                    this.error.savedata.designation = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.designation)){
                        this.showSnackbar("#b71c1c", "Enter valid designation...");
                        return;
                }else if (this.savedata.industry == null) {
                    this.showSnackbar("#b71c1c", "Select Industry ..");
                    this.error.savedata.industry = true;
                    return;
                } else if (this.savedata.year == null || this.savedata.year <0) {
                    this.showSnackbar("#b71c1c", "Select  Year..");
                    this.error.savedata.year = true;
                    return;
                } else if (this.savedata.months == null) {
                    this.showSnackbar("#b71c1c", "Select Months...");
                    this.error.savedata.months = true;
                    return;
                } else if (this.savedata.isworking == null) {
                    this.showSnackbar("#b71c1c", "Select Is Currently Working ...");
                    this.error.savedata.isworking = true;
                    return;
                }
                else if (this.savedata.file == null) {
                    this.showSnackbar("#b71c1c", "Please select experience letter ...");
                    this.error.savedata.file = true;
                    return;
                }
                else if (this.savedata.description == null) {
                    this.showSnackbar("#b71c1c", "Enter Description ...");
                    this.error.savedata.description = true;
                    return;
                }else if(this.hasWhiteSpace(this.savedata.description)){
                        this.showSnackbar("#b71c1c", "Enter valid description...");
                        return;
                }
                 else if (this.savedata.start_date == null) {
                    this.showSnackbar("#b71c1c", "Select Start Date ...");
                    this.error.savedata.start_date = true;
                    return;
                }
                 else if (this.savedata.end_date == null) {
                    this.showSnackbar("#b71c1c", "Select  End Date ...");
                    this.error.savedata.end_date = true;
                    return;
                }else if(this.savedata.end_date < this.savedata.start_date){
                    this.showSnackbar("#b71c1c", "Valid Date Selection ...");
                    return;
                }
                 else {

                     //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                    
                     var file = this.savedata.file.name;
                         var ext = file.split(".");
                            ext = ext[ext.length - 1].toLowerCase();
                            var arrayExtensions = ["pdf","jpeg","jpg"];

                            if (arrayExtensions.lastIndexOf(ext) == -1) {
                                // alert("Invalid file.. Only pdf can be uploaded");
                                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                            return
                            }
                    
                    //for only allow upload cv when size is 250kb
                    const upload = this.savedata.file;
                    const maxSize = 250 * 1024;
                    if(upload.size > maxSize){
                        this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                        return
                    }

                    let formData = new FormData();
                    formData.append("org", this.savedata.org_name);
                    formData.append("designation", this.savedata.designation);
                    formData.append("industry", this.savedata.industry);
                    formData.append("months", this.savedata.months);
                    formData.append("year", this.savedata.year);
                    formData.append("start_date", this.savedata.start_date);
                    formData.append("end_date", this.savedata.end_date);
                    formData.append("description", this.savedata.description);
                    formData.append("file", this.savedata.file);
                    formData.append("isworking", this.savedata.isworking);

                    this.dialog_add = false;
                    this.dialog_upload = true;
                    axios
                        .post("/LearnerData/saveExperience", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.dialog_upload = false;
                                (this.savedata.description = null),
                                    (this.savedata.file = null),
                                    (this.savedata.start_date = null),
                                    (this.savedata.end_date = null),
                                    (this.savedata.designation = null),
                                    (this.savedata.year = null),
                                    (this.savedata.months = null),
                                    (this.savedata.org_name = null),
                                    (this.savedata.industry = null),
                                    (this.savedata.designation = null),
                                    this.showSnackbar("#4caf50", "Add Successfully..."); // show snackbar on success
                            } else {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", " Already Present!!!");
                            }
                        })
                        .catch((error) => {
                           // window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            edit() {
                if (!this.edit_data.org_name) {
                    this.showSnackbar("#b71c1c", "Enter Orgnaization Name...");
                    this.error.edit_data.org_name = true;
                    return;
                }else if(this.hasWhiteSpace(this.edit_data.org_name)){
                        this.showSnackbar("#b71c1c", "Enter valid organization name...");
                        return;
                }
                 else if (!this.edit_data.designation) {
                    this.showSnackbar("#b71c1c", "Enter Designation ..");
                    this.error.edit_data.designation = true;
                    return;
                } else if(this.hasWhiteSpace(this.edit_data.designation)){
                        this.showSnackbar("#b71c1c", "Enter valid designation...");
                        return;
                }
                else if (!this.edit_data.industry) {
                    this.showSnackbar("#b71c1c", "Select Industry ..");
                    this.error.edit_data.industry =true;
                    return;
                } else if (this.edit_data.year == null) {
                    this.showSnackbar("#b71c1c", "Select  Year..");
                    this.error.edit_data.year =true;
                    return;
                } else if (this.edit_data.months == null) {
                    this.showSnackbar("#b71c1c", "Select Months...");
                    this.error.edit_data.month =true;
                    return;
                } else if (this.edit_data.isworking == null) {
                    this.showSnackbar("#b71c1c", "Select  Is Working ...");
                    this.error.edit_data.isworking =true;
                    return;
                }else if (this.edit_data.file == null) {
                    this.showSnackbar("#b71c1c", "Please select experience letter ...");
                    this.error.edit_data.file = true;
                    return;
                } 
                else if (!this.edit_data.description) {
                    this.showSnackbar("#b71c1c", "Enter Description ...");
                    this.error.edit_data.description =true;
                    return;
                } else if(this.hasWhiteSpace(this.edit_data.description)){
                        this.showSnackbar("#b71c1c", "Enter valid description...");
                        return;
                }
                else if (this.edit_data.start_date == null) {
                    this.showSnackbar("#b71c1c", "Select  Start Date ...");
                    this.error.edit_data.start_date =true;
                    return;
                } else if (this.edit_data.end_date == null) {
                    this.showSnackbar("#b71c1c", "Select  End Date ...");
                    this.error.edit_data.end_date =true;
                    return;
                } else if(this.edit_data.end_date < this.edit_data.start_date){
                    this.showSnackbar("#b71c1c", "Valid Date Selection ...");
                    return;
                }
                else {

                    //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                    
                    var file = this.edit_data.file.name;
                         var ext = file.split(".");
                            ext = ext[ext.length - 1].toLowerCase();
                            var arrayExtensions = ["pdf","jpeg","jpg"];

                            if (arrayExtensions.lastIndexOf(ext) == -1) {
                                // alert("Invalid file.. Only pdf can be uploaded");
                                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                            return
                            }
                    
                    //for only allow upload cv when size is 250kb
                    const upload = this.edit_data.file;
                    const maxSize = 250 * 1024;
                    if(upload.size > maxSize){
                        this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                        return
                    }
                    let formData = new FormData();
                    formData.append("org", this.edit_data.org_name);
                    formData.append("designation", this.edit_data.designation);
                    formData.append("industry", this.edit_data.industry);
                    formData.append("months", this.edit_data.months);
                    formData.append("year", this.edit_data.year);
                    formData.append("start_date", this.edit_data.start_date);
                    formData.append("end_date", this.edit_data.end_date);
                    formData.append("description", this.edit_data.description);
                    formData.append("file", this.edit_data.file);
                    formData.append("isworking", this.edit_data.isworking);
                    formData.append("id", this.edit_data.id);

                    this.dialog_edit = false;
                    this.dialog_upload = true;
                    axios
                        .post("/LearnerData/editExperience", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.dialog_upload = false;

                                this.showSnackbar("#4caf50", "Update Successfully..."); // show snackbar on success
                            } else {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", " Already Present!!!");
                            }
                        })
                        .catch((error) => {
                           // window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            delId(item) {
                this.dialog_delete = true;
                this.deleteItem.exp_id = item.id;
            },
            deletedata() {
                this.dialog_delete = false;

                axios.post("/LearnerData/deleteExperience", this.deleteItem).then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " Record Delete Successfully...");
                            th;
                        }
                    } else {
                        this.showSnackbar("#b71c1c", "Record Not Delete...");
                    }
                });
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
    .ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .example {
        margin-top: 0px;
        background: #ffff;
        border-bottom: 1px solid rgb(161, 158, 158);
        width: 300px;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .alink {
        text-decoration: none;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
</style>
